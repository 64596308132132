import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@fscrypto/ui";

interface SortByProps {
  onChange?: (v: string) => void;
  sortBy?: string;
}

function SortBy<T extends string>({ onChange, sortBy }: SortByProps) {
  const value = options.find((o) => o.value === sortBy);
  return (
    <Select defaultValue={value?.value} onValueChange={(v: T) => onChange?.(v)} data-testid="discover-sortby-filter">
      <SelectTrigger size="md">
        <SelectValue placeholder="Sort by" />
      </SelectTrigger>
      <SelectContent>
        {options.map((o) => (
          <SelectItem key={o.value} value={o.value!}>
            {o.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

const iconClassName = "h-5 w-5 mr-2";

const options = [
  { name: "Trending", value: "trending", icon: <span className={iconClassName}>🔥</span> },
  { name: "Newest", value: "new", icon: <span className={iconClassName}>✨</span> },
  { name: "All Time", value: "greatest", icon: <span className={iconClassName}>🏆</span> },
] as const;
export default SortBy;
